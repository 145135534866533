import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import ScrollDetector from "../components/ScrollDetector";
import Layout from "../components/layout";
import SEO from "../components/seo";
import "../components/contact.css";

export default function Contact() {
  const data = useStaticQuery(graphql`
    query {
      allContentfulContact {
        edges {
          node {
            address
            platform
          }
        }
      }
    }
  `);
  return (
    <Layout>
      <SEO title="Contact" />
      <ScrollDetector className="contact-container">
        <a
          className="links"
          href="http://eepurl.com/dlk1Uz"
          target="_blank"
          rel="noopener noreferrer"
        >
          NEWSLETTER &#8599;
        </a>
        <br />
        <a
          href="https://www.instagram.com/louisjack___/?hl=en"
          className="links"
          target="_blank"
          rel="noopener noreferrer"
        >
          INSTAGRAM &#8599;
        </a>
        <br />
        <a
          href="https://vimeo.com/louisjack"
          className="links"
          target="_blank"
          rel="noopener noreferrer"
        >
          VIMEO &#8599;
        </a>
        <br />
        <br />
        <br />
        {data.allContentfulContact.edges.map((x) => (
          <>
            <div className="contact-unit">
              <p className="contact-unit-title">
                {x.node.platform.toUpperCase()}:
              </p>
              <p className="contact-unit-detail">{x.node.address}</p>
            </div>
            <br />
          </>
        ))}
      </ScrollDetector>
    </Layout>
  );
}
