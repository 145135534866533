import React, { useEffect, useRef, useState } from "react";
import down from "../assets/down.svg";

export default function ScrollDetector({ children, className }) {
  const listInnerRef = useRef();
  const [reachedBottom, setReachedBottom] = useState(false);
  const [arrowVisible, setArrowVisible] = useState(true);

  const onScroll = () => {
    if (listInnerRef && listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight + 3 >= scrollHeight) {
        setReachedBottom(true);
      } else {
        setReachedBottom(false);
      }
    }
  };

  function setVisibility() {
    if (listInnerRef.current) {
      const { scrollHeight, clientHeight } = listInnerRef.current;
      scrollHeight <= clientHeight
        ? setArrowVisible(false)
        : setArrowVisible(true);
    }
  }

  useEffect(() => {
    if (listInnerRef) {
      setVisibility();
      window.addEventListener("resize", setVisibility);
    }
  }, []);

  return (
    <>
      <div className={className} onScroll={onScroll} ref={listInnerRef}>
        {children}
      </div>
      {arrowVisible && !reachedBottom && (
        <div className="down-button">
          <img src={down} />
        </div>
      )}
    </>
  );
}
